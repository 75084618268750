import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { useFieldVerifiersContext } from "../../Providers/FieldVerifiersProvider";

import { database } from "../../Firebase/Firebase";
import { useAuthContext } from "../../Providers/AuthProvider";
import { useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import {
  FaUsers,
  FaUserPlus,
  FaFileAlt,
  FaThumbsUp,
  FaComment,
  FaShare,
  FaCheckSquare,
  FaAddressBook,
  FaAddressCard,
} from "react-icons/fa";
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: "black",
  transition: "all 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "5px",
  },
}));

const StyledIcon = styled(Box)(({ theme }) => ({
  fontSize: "2rem",
  marginBottom: theme.spacing(1),
  color: "gray",
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: "red",
  marginTop: theme.spacing(2),
}));
const SummaryPage = () => {
  const [totalCount, setTotalCount] = useState(0); // Total assignments count
  const { user } = useAuthContext();
  const { fvs } = useFieldVerifiersContext(); 

  const [loading, setLoading] = useState(true);
  const [submittedAss, setSubmittedAss] = useState([]); // Submitted assignments
  const [error, setError] = useState(null);

  const documentStatus = 1500; // Static or dynamically calculated
  const navigate = useNavigate(); // Initialize useNavigate

  const [assignedCount, setAssignedCount] = useState(0); // Assigned assignments count
  const [inProgressCount, setInProgressCount] = useState(0); // In-Progress assignments count
  const [submittedCount, setSubmittedCount] = useState(0); // Submitted assignments count
  const [approvedCount, setApprovedCount] = useState(0); // Approved assignments count
const[reassignedCount,setReassignedCount]=useState(0);
  // Function to fetch counts by status
  const getAssignmentsCountByStatus = (status, setCount) => {
    if (user && user.uid) {
      const q = query(
        collection(database, "assignments"),
        where("agency", "==", user.uid),
        where("status", "==", status),
        orderBy("assigned_at", "desc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        setCount(snapshot.size);
        setLoading(false);
      });

      return unsubscribe;
    }
  };

  // Fetch total assignments count
  const getAssignmentsTotalCount = () => {
    if (user && user.uid) {
      const q = query(
        collection(database, "assignments"),
        where("agency", "==", user.uid),
        orderBy("assigned_at", "desc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        setTotalCount(snapshot.size);
        setLoading(false);
      });

      return unsubscribe;
    }
  };

  // Fetch submitted assignments
  useEffect(() => {
    let unsubscribe = () => {};
    if (user !== null && user.uid !== undefined) {
      const q = query(
        collection(database, "assignments"),
        where("agency", "==", user.uid),
        where("status", "==", "submitted")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const ass = [];
        snapshot.docs.forEach((doc) => {
          ass.push({ ...doc.data(), id: doc.id });
        });
        setSubmittedAss(ass);
      });
    }
    return () => unsubscribe();
  }, [user]);

  // Fetch assignment counts based on status
  useEffect(() => {
    let unsubscribeAssigned = getAssignmentsCountByStatus("assigned", setAssignedCount);
    let unsubscribeInProgress = getAssignmentsCountByStatus("in_progress", setInProgressCount);
    let unsubscribeSubmitted = getAssignmentsCountByStatus("submitted", setSubmittedCount);
    let unsubscribeApproved = getAssignmentsCountByStatus("approved", setApprovedCount);
let unsubscribeReassigned = getAssignmentsCountByStatus("reassigned", setReassignedCount);
    return () => {
      if (unsubscribeAssigned) unsubscribeAssigned();
      if (unsubscribeInProgress) unsubscribeInProgress();
      if (unsubscribeSubmitted) unsubscribeSubmitted();
      if (unsubscribeApproved) unsubscribeApproved();
      if (unsubscribeReassigned) unsubscribeReassigned();
    };
  }, [user]);

  // Line chart data
  const lineChartData = [
    { name: `Assigned (${assignedCount})`, value: assignedCount },
    { name: `In-Progress(${inProgressCount})`, value: inProgressCount },
    { name: `Submitted(${submittedCount})`, value: submittedCount },
    { name: `Approved(${approvedCount})`, value: approvedCount },
    {name:`ReAssigned (${reassignedCount})`,value:reassignedCount},
  ];

  const pieChartData = [
    { name: 'Pending', value: submittedAss.length },
    { name: 'Approved', value: approvedCount },
  ];
  const COLORS = ['#FFC107', '#4CAF50'];


  // Load total assignments data on mount
  useEffect(() => {
    const unsubscribe = getAssignmentsTotalCount();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const metricsData = [
    { name: "Assignments", value: totalCount, icon: FaUsers, color: "#62569A", onClick: () => navigate('/dashboard/assignments') },
    { 
      name: "Verifications", 
      value: "", 
      icon: FaCheckSquare, 
      color: "#28A745", 
      onClick: () => navigate('/dashboard/assignment/verify'),
      subMetrics: [
        { name: "Pending List",  value: submittedAss.length, color: "#FF5733", onClick: () => navigate('/dashboard/pendingList') },
        { name: "Verified List", value: approvedCount,  color: "#4CAF50", onClick: () => navigate('/dashboard/verifiedList') },
      ]
    },    { name: "Document Status", value: documentStatus, icon: FaFileAlt, color: "#FFC107" , onClick: () => navigate('/dashboard/assignments')},
    { name: "Field Verifiers", value: fvs.length, icon: FaAddressCard, color: "#17A2B8", onClick: () => navigate('/dashboard/fieldVerifierPage') },
  ];

  
  
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={2}>
          {/* Display metrics data */}
          {metricsData.map((metric, index) => (
            <Grid item xs={8} sm={6} md={3} key={index}>
              <StyledPaper
                elevation={4}
                aria-label={`${metric.name}: ${metric.value}`}
                onClick={metric.onClick ? metric.onClick : undefined}
                style={{ cursor: metric.onClick ? 'pointer' : 'default' }}
              >
                <StyledIcon as={metric.icon} color={metric.color} />
                <Typography variant="h6">{metric.name}</Typography>

                <Typography variant="h5">
                  {loading && metric.name === "Assignments" ? (
                    <CircularProgress size={24} />
                  ) : (
                    metric.value.toLocaleString()
                  )}
                </Typography>

                {metric.subMetrics && (
       <>
          {metric.subMetrics.map((subMetric, subIndex) => (
          <>
         
             <Typography sx={{fontSize:'12px'}}>{subMetric.name}-  {subMetric.value.toLocaleString()}</Typography>
             
              </>
          ))}
          </>
        
      )}

              </StyledPaper>
            </Grid>
          ))}
        </Grid>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Box>

      <Box sx={{ mt: 4,p:3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} >
              <Typography variant="h6" gutterBottom sx={{ p: 2 }}>
                Assignments Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={lineChartData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#8884d8"  />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        
          <Grid item xs={12} md={4}>
            <Paper elevation={3}>
              <Typography variant="h6" gutterBottom sx={{ p: 2 }}>
                Verification Status
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

        </Grid>
      </Box>
    </>
  );
};

export default SummaryPage;
