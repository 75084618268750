import React from 'react';
import { useFieldVerifiersContext } from '../../Providers/FieldVerifiersProvider';
import FieldVerifierCard from '../../Elements/FieldVerifierCard/FieldVerifierCard';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import './FieldVerifierPage.css';


const FieldVerifierPage = () => {
  const { fvs } = useFieldVerifiersContext();
  const { state } = useLocation();
  const mode = state?.mode || null; // Use optional chaining and a fallback

  // Filter out empty fields and fields containing "8768715527"
  const existingFVs = fvs.filter(element => element && element !== "8768715527");

  return (
    <div style={{ width: "100%" }}>
      <Grid container style={{
        margin: '0.4em 0em',
        fontSize: '14px',
        color: '#404040',
        fontWeight: 'bold'
      }}>
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>ID</Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Name</Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Phone</Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Actions</Grid>
      </Grid>
      <hr style={{ width: '93%', margin: '0 auto 0.15em auto', border: '0.6px solid #dedede' }} />

      {/* Display existing field verifier count */}
      <div style={{ textAlign: 'center', margin: '0.5em 0', color: 'gray', fontSize: '14px' }}>
        Existing Field Verifiers: {existingFVs.length}
      </div>

      {existingFVs.map((element, index) => (
        <div key={index}>
          <FieldVerifierCard
            uid={element}
            select={mode === "select" ? 1 : 0} />
        </div>
      ))}
    </div>
  );
}

export default FieldVerifierPage;